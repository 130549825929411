$( function() {
    /**
    * Mắc định chạy khi chạy web
    */
    // Mặc định addClass "is-empty" cho tất cả form-group.
    $(".form-group").each(function() {
        var $value = $(this).find('input').val();
        var $valuetextarea = $(this).find('textarea').val();
        if($value === '' || $valuetextarea === ''){
            $(this).addClass('is-empty');
        }
    });
    // Checkbox, Radio, Toggle || Thêm element cần thiết 
    $(".checkbox").find('input').after('<span class="checkbox__tick"><span class="check"></span></span>');
    $(".radio").find('input').after('<span class="radio__circle"><span class="radio__check"></span></span>');
    $(".togglebutton").find('input').after('<span class="toggle"></span>');
    //Tabs, thiết lập chiều cao, chiều rộng cho element tab
    if (window.innerWidth > 769) {
        $(".tabs-list").find("li:first").addClass("is-active");
        heightTabs();
        widthTabs();
    }
    /**
    * Xử lý khi resize
    */
    var w = $(window).width();
    $(window).resize(function() {
        if (window.innerWidth != w) {
            if (window.innerWidth > 1024) {
                $(".menu__collapse").css("display", "block");
            } else {
                $(".menu__collapse").css("display", "none");
            }
            if (window.innerWidth > 769) {
                $(".tabs-list").find("li").removeClass("is-active");
                $(".tabs-list").find("li:first").addClass("is-active");
                heightTabs();
                widthTabs();
            } else {
                $(".tabs").removeAttr("style");
                $(".tabs-list").find("li").removeClass("is-active");
            }
        }
    });
    /**
    * Xử lý khi có sự kiện của bàn phím, chuột,...
    */
    // focus event
    $("body").on("focus", "input, textarea", function() {
        // select single choice
        if ($(this).closest(".form__select") && $(this).attr("type") == "radio") {
            $(this).closest(".form-group").removeClass("is-focused");
            $(this).closest(".dropdownjs").removeClass("is-focused");
            $(".overlay-transparent").remove();
        } else {
            $(this).closest(".form-group").addClass("is-focused");
        }
    });
    /**
    * blur event
    */
    $("body").on("blur", "input:not([type=checkbox]), textarea, [ht-trigger=dropdownjs]", function() {
        $(this).closest(".form-group").removeClass("is-focused");
        $(this).closest(".dropdownjs").removeClass("is-focused");
    });
    // Xử lý sự kiện trigger
    $("body").on("click", "[ht-trigger]", function() {
        // Phần chung cho tất cả trigger
        var $thisTrigger = $(this);
        var $trigger = $thisTrigger.attr("ht-trigger");
        var $submit = $thisTrigger.attr("ht-type");
        var $target = $thisTrigger.attr("ht-target");
        switch ($trigger) {
          case "dropdownjs":
            if ($(this).closest(".dropdownjs").hasClass("is-focused")) {
                $(this).closest(".dropdownjs").removeClass("is-focused");
            } else {
                $(this).closest(".dropdownjs").addClass("is-focused");
            }
            break;
            /**
            * Xử lý Select || Xử lý hiển thì khung checkbox
            */
            case "select":
            var $htempty = $(this).attr("ht-empty");
            $(this).parent().addClass("is-focused");
            $("body").append('<div class="overlay-transparent" ht-close="select"></div>');// Mục đích để đóng box khi click anywhere
            break;
            /**
            * Xử lý alert || Xử lý sự kiện khi đóng alert
            */
            case "alert":
            $thisTrigger.parent().slideUp(300);
            setTimeout(function() {
                $thisTrigger.parent().remove();
            }, 300);
            break;
            /**
            * Xử lý Modal || Xử lý sự kiện hiển thị popup
            */
            case "modal":
            // Hiển thị background mờ
            $("body").append('<div class="modal-backdrop"></div>');
            $("body").addClass("modal-open");// không chô scroll
            $(".modal-backdrop").addClass("show");
            // Hiển thị box modal
            $target = $($thisTrigger.attr("ht-target"));
            //box muốn hiển thị.
            $target.css("display", "block");
            $targetClose = $thisTrigger.attr("ht-target-close");
            //box muốn đóng.
            if ($targetClose !== undefined) {
                $($targetClose).css("display", "none");
                $("body").find(".modal-backdrop:last").remove();
            }
            // sau 200ms thì sẽ chạy
            setTimeout(function() {
                $target.addClass("show");
            }, 200);
            break;
            /**
            * Menu || Đóng mở menu khi qua mobile
            */
            case "navbar":
            $($target).slideToggle(200);
            break;
            /**
            * FAQ || collapse, accordion
            */
            case "FAQ":
            var $typeShow = $thisTrigger.attr("ht-show");
            if ($thisTrigger.parent().hasClass("is-active")) {
                $thisTrigger.parent().find($target).css("height", "0px");
                $thisTrigger.parent().removeClass("is-active");
            } else {
                if ($typeShow == "onlyone") {
                    $(".accor").removeClass("is-active");
                    $(".accor").find(".accor__body").css("height", "0px");
                }
                $thisTrigger.parent().addClass("is-active");
                var $height = $thisTrigger.parent().find(".accor__body div").outerHeight();
                $thisTrigger.parent().find($target).css("height", $height + "px");
            }
            break;
            /**
            * Tabs
            */
            case "tabs":
            var $parent = $thisTrigger.parent();
            /**
            * Tabs, thiết lập chiều cao cho element tab cho desktop và mobile
            */
            if (window.innerWidth > 769) {
                $thisTrigger.closest(".tabs").find("li").removeClass("is-active");
                $parent.addClass("is-active");
                heightTabs();
            } else {
                $parent.hasClass("is-active") ? $parent.removeClass("is-active") : $parent.addClass("is-active");
            }
            break;
            /**
             * Animations.
             */
            // Hiệu ứng khi click event.
            // yêu cầu có attribute "ht-animation" và tên phụ animation thuộc vào class trong file animation.css
            //Document : https://daneden.github.io/animate.css/
            case "animation":
            $thisTrigger.addClass("animated " + $target);
            setTimeout(function() {
                $thisTrigger.removeClass('animated ' + $target )    ;// sau 3s trở về trạng thái ban đầu
            }, 3000);
            break;
            /**
             * Reply
             */
            case "reply":
            $thisTrigger.closest(".comment__body").find(".comment--post").slideDown("slow/400/fast");
            break;
            /**
            *   table
            */
            case "table":
            var $type = $thisTrigger.attr("ht-type");
            var $quantity = $thisTrigger.parent().find("input").val();
            var $price = $thisTrigger.closest("tr").find(".td-price span").text();
            var $total = $thisTrigger.closest("tr").find(".td-total span").text();
            var $totalAll = $thisTrigger.closest(".table").find(".td-totalAll span").text();
            $price = $price.replace(/\./g, "");// xóa đấu "."
            $total = $total.replace(/\./g, "");// xóa đấu "."
            $totalAll = $totalAll.replace(/\./g, "");// xóa đấu "."
            // tính toán tăng/giảm số lượng
            if ($type == "plus") {
                $quantity++;
            }
            if ($type == "minus" && $quantity > 1) {
                $quantity--;
            }
            // Xóa item và tính lại tiền
            if ($type == "delete") {
                $totalAll = $totalAll - $total;
                $thisTrigger.closest("tr").hide(700);
            } else {
                $totalAll = $totalAll - $total;
                $total = $price * $quantity;
                $totalAll = $totalAll + $total;
            }
            // Xuất dữ liệu ra màng hình
            $thisTrigger.parent().find("input").val($quantity);
            $thisTrigger.closest("tr").find(".td-total span").text(formatNumber($total));
            $thisTrigger.closest(".table").find(".td-totalAll span").text(formatNumber($totalAll));
            break;
            /**
            *   Sidebar khi chuyển qua mobile
            */
            case "sidebar":
            if (!$thisTrigger.parent().hasClass("is-active")) {
                $thisTrigger.closest("ul").find("li").removeClass("is-active");
                $thisTrigger.closest("ul").find("ul.level-2").slideUp(700);
                $thisTrigger.parent().addClass("is-active");
            } else {
                $thisTrigger.parent().removeClass("is-active");
            }
            $thisTrigger.parent().find("ul.level-2").slideToggle(700);
            break;
            /**
            *   Sidebar load thêm data
            */
            case "sidebar-loadMore":
            $thisTrigger.parent().find(".sidebar__items").css("overflow", "auto");
            $thisTrigger.remove();
            break;
            /**
            *   Comment || Show textarea trả lời comment
            */
            case "comment":
            $thisTrigger.closest(".comment__body").find(".comment--post").addClass("show");
            break;
        }
    });
    /**
    * Xử lý attribute ht-close
    */
    $("body").on("click", "[ht-close]", function() {
        // Phần chung cho tất cả trigger
        var $thisClose = $(this);
        var $close = $thisClose.attr("ht-close");
        var $reload = $thisClose.attr("ht-reload");
        switch ($close) {
          case "modal":
            $target = $thisClose.attr("ht-target-close");
            if ($target) {
                $($target).removeClass("show");
                $("body").find(".modal-backdrop:last").removeClass("show");
                setTimeout(function() {
                    $($target).css("display", "none");
                    $("body").find(".modal-backdrop:last").remove();
                }, 200);
            } else {
                $(".modal-backdrop").removeClass("show");
                $(".modal").removeClass("show");
                setTimeout(function() {
                    $(".modal").css("display", "none");
                    $("body").removeClass("modal-open");
                    $(".modal-backdrop").remove();
                }, 200);
            }
            break;
          case "select":
            $(".dropdownjs").removeClass("is-focused");
            $(".overlay-transparent").remove();
            break;
          case "fileuploader":
            $(this).closest(".uploader").removeClass("is-active");
            break;
        }
        if ($reload === "true") {
            $redirect = $thisClose.attr("ht-redirect") === undefined ? "" : $thisClose.attr("ht-redirect");
            // location.reload();
            window.location.href = $redirect;
        }
    });
    /**
    * Xử lý attribute ht-skip || thực hiện sự kiện nhưng bỏ qua sự kiện parent
    */
    $("body").on("click", "[ht-skip]", function(e) {
        e.stopPropagation();
    });
});

/**
* Hiệu ứng star // đánh giá
*/
// hiệu ứng active khi hover
$(document).ready(function() {
    $(".star__item label").hover(function() {
        var $index = $(this).attr("ht-index");
        $(this).parent().find("label").removeClass("is-active");
        for ($i = 1; $i <= $index; $i++) {
            $(this).parent().find("label:nth-child(" + $i + ")").addClass("is-active");
        }
    }, function() {
        var $indexActive = $(this).parent().find("[ht-checked]").attr("ht-index");
        $(this).parent().find("label").removeClass("is-active");
        for ($i = 1; $i <= $indexActive; $i++) {
            $(this).parent().find("label:nth-child(" + $i + ")").addClass("is-active");
        }
    });
});
// hiệu ứng active khi click (checked)
$(document).ready(function() {
    $(".star__item label").click(function() {
        var $index = $(this).attr("ht-index");
        $(this).parent().find("label[ht-checked]").removeAttr("ht-checked");
        $(this).attr("ht-checked", true);
        for ($i = 1; $i <= 5; $i++) {
            if ($i <= $index) {
                $(this).parent().find("label:nth-child(" + $i + ")").addClass("is-active");
                $(this).parent().find("label:nth-child(" + $i + ") input").prop("checked", true);
            } else {
                $(this).parent().find("label:nth-child(" + $i + ")").removeClass("is-active");
                $(this).parent().find("label:nth-child(" + $i + ") input").prop("checked", false);
            }
        }
    });
});

/**
* Select
*/
//Select || Xử lý select 1 giá trị duy nhât 
$(document).ready(function() {
    $("body").on("click", "input[type=radio]", function() {
        var $this = $(this);
        var $value = $this.parent().find("span").text();
        $this.closest(".dropdownjs").find("div.form__input").text($value);
        $this.closest(".dropdownjs").find("input.form__input").val($value);
        $this.closest(".dropdownjs").find(".radio").removeClass("is-selected");
        $this.closest(".radio").addClass("is-selected");
    });
});
// Select multi || Xử lý select chọn nhiều giá trị.  || Sẽ tách riêng
$(document).ready(function() {
    // Lấy all giá trị được chọn và ghi vào input để hiển thị
    $("input[type=checkbox]").click(function() {
        var $this = $(this);
        var $value = "";
        var $htempty = $this.closest(".dropdownjs").find("div.form__input").attr("ht-empty");
        $this.closest(".dropdownjs").find("input:checked").each(function() { // Vòng lặp để lấy tất cả giá trị được chọn
            $value += $(this).parent().find("span").text() + ", ";
        });
        $value = $value.slice(0, -2); // Xóa bỏ 2 ký tự sau cùng
        if ($value !== "") {
            $this.closest(".dropdownjs").find("div.form__input").text($value);
        } else {
            $this.closest(".dropdownjs").find("div.form__input").text($htempty);
        }
    });
});

/**
* Tooltips
*/
$(document).ready(function() {
    $("body").on("hover", "[ht-trigger=tooltips]", function(e) {
        if (e.type == "mouseenter") {
            var $this = $(this);
            var $content = $this.attr("ht-content"); // Lấy nội dung text.
            var $placement = $this.attr("ht-placement"); // nhận biết vị trí hiển thị (top, bottom, left, right).
            $("body").append('<div class="tooltips ' + $placement + '">' + $content + "</div>"); // thêm elements vào cuối body
            var $position = $this.offset(); // lấy vị trí tọa đồ của btn (top, left)
            var $widthBtn = $this.outerWidth(); // lấy chiều dài của btn
            var $heightBtn = $this.outerHeight(); //lấy chiều cai hộp thoại
            var $widthTooltop = $(".tooltips." + $placement).outerWidth(); // chiều dài của tooltip
            var $heightTooltop = $(".tooltips." + $placement).outerHeight(); // chiều dài của tooltip
            var $width = ($widthTooltop - $widthBtn) / 2;// tính toán canh giữa theo chiều dài
            var $height = ($heightTooltop - $heightBtn) / 2; // tính toán canh giữa theo chiều rộng
            // kiểm tra vị trí và gán vị trí chính xác
            if ($placement == "top") {
                $height = $(".tooltips." + $placement).outerHeight();
                $top = $position.top - $height - 10;
                $left = $position.left - $width;
            } else if ($placement == "bottom") {
                $height = $this.outerHeight();
                $top = $position.top + $height + 10;
                $left = $position.left - $width;
            } else if ($placement == "left") {
                $top = $position.top - $height;
                $left = $position.left - $widthTooltop - 10;
            } else if ($placement == "right") {
                $top = $position.top - $height;
                $left = $position.left + $widthBtn + 10;
            }
            $(".tooltips." + $placement).css({
                top: $top,
                left: $left
            });
            setTimeout(function() {
                $(".tooltips").addClass("show");
            }, 200);
        } else {
            $(".tooltips").remove();
        }
    });
});

/**
* Popovers
*/
$(document).ready(function() {
    $("[ht-trigger=popovers]").click(function() {
        var $this = $(this);
        var $placement = $this.attr("ht-placement"); // nhận biết vị trí hiển thị.
        // nếu có "is-active" thì đóng hộp thoại
        if ($this.hasClass("is-active")) {
            $(".popovers." + $placement).removeClass("show");
            $this.removeClass("is-active");
            setTimeout(function() {
                $(".popovers." + $placement).remove();
            }, 200);
        } else {
            $this.addClass("is-active");
            var $content = $this.attr("ht-content"); // Lấy nội dung text.
            var $title = $this.attr("ht-title"); // Lấy nội dung title.
            var $str = '<div class="popovers ' + $placement + '">' + '<div class="popovers__title">' + $title + "</div>" + '<div class="popovers__content">' + $content + "</div>" + "</div>";
            $("body").append($str); // thêm elements vào cuối body
            var $position = $this.position(); // lấy vị trí tọa đồ của btn (top, left)
            var $widthBtn = $this.outerWidth(); // chiều dài của btn
            var $heightBtn = $this.outerHeight();
            var $widthPopovers = $(".popovers." + $placement).outerWidth(); // chiều dài của hộp thoại
            var $heightPopovers = $(".popovers." + $placement).outerHeight(); // chiều dài của hộp thoại
            var $width = ($widthPopovers - $widthBtn) / 2; // tính toán canh giữa theo chiều dài
            var $height = ($heightPopovers - $heightBtn) / 2; // tính toán canh giữa theo chiều rộng
            if ($placement == "top") {
                $height = $(".popovers." + $placement).outerHeight();// chiều cao của btn tính lun cả padding
                $top = $position.top - $height - 10;
                $left = $position.left - $width;
            } else if ($placement == "bottom") {
                $height = $this.outerHeight();
                $top = $position.top + $height + 10;
                $left = $position.left - $width;
            } else if ($placement == "left") {
                $top = $position.top - $height;
                $left = $position.left - $widthPopovers - 10;
            } else if ($placement == "right") {
                $top = $position.top - $height;
                $left = $position.left + $widthBtn + 10;
            }
            $(".popovers." + $placement).css({
                top: $top,
                left: $left
            });
            setTimeout(function() {
                $(".popovers").addClass("show");
            }, 200);
        }
    });
});

/**
* Validetion input
*/
$(document).ready(function() {
    $("input, textarea").change(function() {
        var $this = $(this);
        var $value = $this.val();
        if ($value !== "") {
            $this.closest(".form-group").removeClass("is-empty");
        } else {
            $this.closest(".form-group").addClass("is-empty");
        }
    });
});

// đối tượng cần truyển dữ liệu để xử lý
(function($) {
    // kiểm tra form
    $.fn.htvalidate = function($options) {
        var $form = $(this), $elems = $form.find("input, textarea");
        var $arrName = getKeyJson($options);// trả về mảng key json
        // kiểm tra thông tin khi change
        $elems.change(function() {
            checkInfo($(this));
        });
        // kiểm tra thông tin khi click button
        $form.on("click", "[ht-trigger='form']", function() {
            var $thisTrigger = $(this);
            var flag = true;
            $elems.each(function() {
                if (checkInfo($(this)) === false) {
                    flag = false;
                }
            });
            // có 2 kiểu submit, liên quan tới attr "ht-validate" của btn
            if (flag === true) {
                $submit = $thisTrigger.attr("ht-type");
                //- submit không load trang: $submit == "ajax"
                if ($submit == "ajax") {
                    var $url = $thisTrigger.attr("ht-url");
                    var $domain = $thisTrigger.attr("ht-domain");
                    form_ajax($form, $url, $domain);
                } else {
                    // - submit load trang: $submit == "submit" hoặc undefined
                    $thisTrigger.closest("form").submit();
                }
            }
        });
        // Hàm kiểm tra thông tin input
        function checkInfo($this) {
            var flag = '';
            var $elementName = $this.attr("name");// Lấy giá trị value
            $this.closest(".form-group").removeClass("is-error");
            $this.closest(".form-group").find(".form__error, .error-text").remove();
            if ($arrName[$elementName] !== undefined) {
                var $value = $this.val();
                // thiết lập vị trí hiển thị || kiểu hiện lỗi
                var $position = $options[$elementName].position ? $options[$elementName].position : "left";
                var $typeNotify = $options[$elementName].typeNotify ? $options[$elementName].typeNotify : "text";
                // thiết lập thông báo chung
                $this.closest(".form-group").removeClass("is-empty");
                var $arrType = getKeyJson($options[$elementName]);// trả về mảng key json
                arrTypes = [ "position", "typeNotify" ];// mảng loại trừ $type không kiểm tra
                for (var $type in $arrType) {
                    isTrue = arrTypes.indexOf($arrType[$type]) > -1;
                    if (!isTrue) {
                        var result = validation($this, $type, $value);
                        if (result === true) {// if trả về true
                            $this.closest(".form-group").removeClass("is-error, is-error--" + $typeNotify);
                        } else {
                            flag = false;
                            $notify = typeof $options[$elementName][$type] !== "object" ? $options[$elementName][$type] : $options[$elementName][$type].notify;
                            var dataJson = {
                                obj: $this,
                                text: $notify,
                                position: $position,
                                typeNotify: $typeNotify
                            };
                            addError(dataJson);
                            break;
                        }
                    }
                }
            }
            return flag;
        }
        // Hàm lấy key trong json trả về 1 mảng
        function getKeyJson($listJson) {
            var $arrName = [];
            for (var $name in $listJson) {
                var $key = $name;
                $arrName[$key] = $key;
            }
            return $arrName;
        }
        // Xuất lỗi input
        function addError(dataJson)
        {
            box = dataJson.obj.closest(".form-group");
            box.addClass("is-error is-error--" + dataJson.typeNotify);
            box.find(".form__error").remove();
            if (dataJson.typeNotify === "text") {
                // hiển thị text
                box.find(".error-text").remove();
                box.append('<span class="form__error"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span><span class="error-text">' + dataJson.text + "</span>");
            } else {
                // hiển thị bằng tooltips
                box.append('<span class="form__error" ht-trigger="tooltips" ht-placement="' + dataJson.position + '" ht-content="' + dataJson.text + '"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>');
            }
        }
        // kiểm tra form
        function validation(obj, type, value)
        {
            var result = "";
            var elementName = obj.attr("name");
            var objJson = $options[elementName];
            switch (type) {
                case "required":
                    result = value.length > 0 ? true : false;
                break;
                case "email":
                    regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;
                    // nếu giá trị rỗng thì return true, để phù hợp với không bắt buộc
                    result = (value !== "") ? regex.test(value) : true;
                break;
                case "number":
                    regex = /^[0-9\b\s]+$/gim;
                    // nếu giá trị rỗng thì return true, để phù hợp với không bắt buộc
                    result = value !== "" ? regex.test(value) : true;
                break;
                case "strlength":
                    result = true;
                    if (objJson.strlength.max && objJson.strlength.min) {
                        if (value.length < objJson.strlength.min || value.length > objJson.strlength.max) {
                            result = false;
                        }
                    } else {
                        if (objJson.strlength.max && value.length > objJson.strlength.max) {
                            result = false;
                        }
                        if (objJson.strlength.min && value.length < objJson.strlength.min) {
                            result = false;
                        }
                    }
                break;
                case "checked":
                    input = obj.closest("form").find("input[name=" + elementName + "]");
                    if (input[0].getAttribute("type") == "radio") {
                        result = false;
                        for (var i = 0; i < input.length; i++) {
                            if (input[i].checked) {
                                result = true;
                                break;
                            }
                        }
                    } else {
                        for (var j = 0; j < input.length; j++) {
                            result = input[j].checked;
                            if (result === true) {
                                break;
                            }
                        }
                    }
                break;
                case "confirm":
                    // kiểm tra confirm || so sanh 2 chuổi bằng nhau return true
                    input = obj.closest("form").find("input[name=" + objJson.confirm.compare + "]");
                    result = input.val() !== value ? false : true;
                break;
                case "exists":
                    dataJson = JSON.parse('{"' + elementName + '": "' + value + '", "event": "change"}');
                    $.ajax({
                        url: objJson.exists.url,
                        type: "POST",
                        data: dataJson,
                        async: false //chương trình sẽ chạy theo từng bước và chỉ khi nào bước 1 thực hiện xong thì mới nhảy sang bước 2
                    })
                    .done(function(data) {
                        data = JSON.parse(data);
                        result = data.error === true ? false : true;
                    });
                break;
            }
            return result;
        }
        function form_ajax(form, url, domain)
        {
            var $el = form.find("input, textarea");
            var $dataJson = "";
            var $dataJsonInput = {},
                $input_json = {};
            $el.each(function(i, el) {
                if (el.getAttribute("name") !== null) {
                    if (el.type != "checkbox" && el.type != "radio") {
                        if (el.getAttribute("name") == "input_json") {
                            $input_json = JSON.parse(el.value);
                        }
                        else if (el.getAttribute("name").indexOf("[") > -1) {
                            $arrNameInput = el.getAttribute("name").replace(/]/g,'').split('[');
                            if ($dataJsonInput[$arrNameInput[0]] === undefined) {
                                $dataJsonInput[$arrNameInput[0]] = {};
                                $dataJsonInput[$arrNameInput[0]][$arrNameInput[1]] = el.value;
                            } else {
                                $dataJsonInput[$arrNameInput[0]][$arrNameInput[1]] = el.value;
                            }
                        }
                        else {
                            $dataJson += '"' + el.getAttribute("name") + '": "' + el.value + '", ';
                        }
                    } else if (el.checked === true) {
                        if (el.getAttribute("name").indexOf("[") > -1) {
                            $arrNameInput = el.getAttribute("name").replace(/]/g,'').split('[');
                            if ($dataJsonInput[$arrNameInput[0]] === undefined) {
                                $dataJsonInput[$arrNameInput[0]] = {};
                                $dataJsonInput[$arrNameInput[0]][$arrNameInput[1]] = el.value;
                            } else {
                                $dataJsonInput[$arrNameInput[0]][$arrNameInput[1]] = el.value;
                            }
                        }
                        else {
                            $dataJson += '"' + el.getAttribute("name") + '": "' + el.value + '", ';
                        }
                    }
                }
            });
            $dataJson = "{" + $dataJson.slice(0, -2) + "}";
            dataJson = JSON.parse($dataJson);

            if ($dataJsonInput) {
                for (var $key in $dataJsonInput) {
                    dataJson[$key] = $dataJsonInput[$key];
                }
            }
            if ($input_json) {
                dataJson.input_json = $input_json;
            }

            $.ajax({
                url: url,
                type: "POST",
                data: dataJson,
                beforeSend: function() {
                    $("body").append('<div class="loading_gif"><img src="'+domain+'images/loading.gif" /></div>');
                }
            })
            .done(function(result) {
                result = JSON.parse(result);
                $("body").find("div.loading_gif").remove();
                if (isNaN(result)) {
                    if (result.modal !== undefined) {
                        $("body").find('.modal--contact').removeClass('active');
                        $(window).onClickNotify(result);
                    }
                    if (result.alert !== undefined) {
                        $(window).onLoadAlert(result);
                    }
                } else {
                    window.location.href = "";
                }
            });
        }
    };
    //hiển thị thông báo bằng modal
    $.fn.onClickNotify = function(dataJson) {
        if (typeof dataJson !== "object") {
            dataJson = JSON.parse(dataJson);
        }
        // hiển thị thông báo bằng modal || có thể thực thi thành công hoặc không thành công.
        el = document.getElementById(dataJson.modal);
        el.querySelector(".modal__body").innerHTML = "<h4>" + dataJson.text + "</h4>";
        el.querySelector(".modal__footer button[ht-close]").setAttribute("ht-target-close", "#" + dataJson.modal);
        if (dataJson.modalBefore) {
            document.getElementById(dataJson.modalBefore).querySelector("[ht-close]").click();
        }
        setTimeout(function() {
            if (dataJson.reload === true) {
                el.querySelector(".modal__footer button[ht-close]").setAttribute("ht-reload", "true");
            }
            if (dataJson.redirect) {
                el.querySelector(".modal__footer button[ht-close]").setAttribute("ht-redirect", dataJson.redirect);
            }
            getElementsByAttr("ht-target", "#" + dataJson.modal).click();
        }, 200);
    };
    // hiển thị thông báo bằng alert
    $.fn.onLoadAlert = function(dataJson) {
        if (typeof dataJson !== "object") {
            dataJson = JSON.parse(dataJson);
        }
        var el_parent = document.getElementById(dataJson.alert);
        var bg = dataJson.classbg !== undefined ? dataJson.classbg : "bg--error";
        var html = "";
        html += '<div class="form-group alert ' + bg + '">' + '<div class="alert__icon"><i class="fa fa-exclamation" aria-hidden="true"></i></div>' + '<div class="alert__close" ht-trigger="alert"><i class="fa fa-times" aria-hidden="true"></i></div>' + '<span class="alert__content"> ' + dataJson.text + "</span>" + "</div>";
        if (isNaN(el_parent.querySelector(".alert"))) {
            el_parent.querySelector(".alert").remove();
        }
        setTimeout(function(){ 
            el_parent.insertAdjacentHTML( 'afterBegin', html );
        }, 200);
    };
    /**
     * [setHeights Thiết lập lại chiều cao cho element theo vòng lặp]
     * @param {[json]} options 
     *  Giá trị mặc định
            $.fn.setHeights.defaults = {
                level: 'min',         {[string]}    [min/max]       [Lấy chiều cao theo element lớn nhất hoặc nhỏ nhất]
                typeHeight: 'outer',  {[string]}    [outer/inner]   [Kiểu lấy chiều cao  .outerHeight()/.innerHeight()]
                maxWidth: false,      {[bool]}      [true/false]    [Thiết lập max-width: auto, width: auto]
                limit: 0,             {[int]}                       [Giới hạn theo options.level]
                resize: 480           {[int]}                       [Kích thước màng hình thực thi]
            };
     * @Syntax 
     *  
        $(document).ready(function() {
            $(window).on("load resize",function() {
                $('<element>').setHeights();
            });
        });
     */
    $.fn.setHeights = function (options){
        var settings = $.extend({}, $.fn.setHeights.defaults, options),
            heights = [], Height = 'auto', location = $(this);
        setTimeout(function() {
            if (window.innerWidth > settings.resize) {
                location.each(function() {
                    // thiết lập style cho từng element
                    $(this).css({
                        'min-height': '1','max-height':'none','height':'auto','overflow':'hidden',
                        'max-width' : (settings.maxWidth) ? '100%' : '',
                        'width'     : (settings.maxWidth) ? '100%' : '',
                        
                    });
                    // lấy tất cả chiều cao ghi thành 1 mảng
                    if (settings.typeHeight == "inner" && $(this).innerHeight() > 0) {
                        heights.push($(this).innerHeight());
                    } else if (settings.typeHeight == "outer" && $(this).outerHeight() > 0) {
                        heights.push($(this).outerHeight());
                    }
                });
                Height = Math[settings.level].apply(Math,heights);
                if (settings.level == "max") {
                    Height = ((settings.limit && Height > settings.limit) ? settings.limit : Height) + 'px';
                } else {
                    Height = ((settings.limit && Height < settings.limit) ? settings.limit : Height) + 'px';
                }
            }
            // thiết lập chiều cao cho element
            location.each(function() {
                $(this).css({
                    'height': Height,
                    'width': (settings.maxWidth) ? 'auto' : '',
                });
            });
        }, 200);
    };
    $.fn.setHeights.defaults = {
        level: 'min',
        typeHeight: 'outer',
        maxWidth: false,
        limit: 0,
        resize: 480
    };
})(jQuery);

/**
* Xây dựng các hàm thư viện
*/
// Lấy input có attribute truyền vào
function getElementsByAttr(attribute, content)
{
    el = document.querySelectorAll("[" + attribute + "]");
    for (var i = 0; i < el.length; i++) {
        if (el[i].getAttribute(attribute) == content) {
            return el[i];
        }
    }
}

// convert int to string with format money
function formatNumber(num)
{
    var array = num.toString().split("");
    var index = -3;
    while (array.length + index > 0) {
        array.splice(index, 0, ".");
        index -= 4;
    }
    return array.join("");
}
// Thiết lập các hàm
// Hàm thiết lập chiều cao cho element tab
function heightTabs()
{
    $(".tabs").each(function() {
        var $heightTabList = $(this).find(".tabs-list .is-active .tabs__title").outerHeight();
        var $heightContent = $(this).find(".tabs-list .is-active .tabs__content").outerHeight();
        if ($(this).hasClass("tabs-vertical")) {
            $heightTabList = 0;
            var $tabsList = $(this).find(".tabs-list").outerHeight();
            if ($tabsList > $heightContent) {
                $heightContent = $tabsList;
            }
        }
        var $heightTabs = $heightTabList + $heightContent + 10;
        $(this).css("height", $heightTabs);
    });
}
// Hàm thiết lập chiều rộng cho element tab
function widthTabs()
{
    $(".tabs-vertical").each(function() {
        var $widthVertical = $(this).outerWidth();
        var $tabsList = $(this).find(".tabs-list").outerWidth();
        var $widthTabs = $widthVertical - $tabsList;
        $(this).find(".tabs__content").css("width", $widthTabs);
    });
}
// Hàm đi kèm với FILE UPLOADER || Hàm lấy element.
function element(id) { return document.getElementById(id); }

// Hàm convert image thành mã basic64
function readFile() 
{
    var $this = $(this);
    var fileTypes = [ "jpg", "jpeg", "png", "gif" ];// khai báo mảng định dạng mở rộng của image
    // kiểm tra là file
    // console.log(closest('.fileuploader').find('.fileuploader__preview'));
    if (this.files && this.files[0]) {
        // kiểm tra phân mở rộng của image
        // kết quả trả về true/false
        var extension = this.files[0].name.split(".").pop().toLowerCase(), // Lấy phần mở rộng của file input
        isSuccess = fileTypes.indexOf(extension) > -1; // Duyệt mảng so sánh, trả về true/false
        // FileReader() lớp của javascipt
        // Document: https://developer.mozilla.org/en-US/docs/Web/API/FileReader
        // hiển thì hình ảnh
        if (isSuccess) {
            var FR = new FileReader();
            FR.onload = function(e) {
                var str = e.target.result; // chỉ lấy value của key "result" trong mảng target
                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/Image
                var image = new Image(); // Image() lớp của javascipt
                image.src = str;
                $this.closest(".uploader").find(".uploader__preview").html(image);
            };
            FR.readAsDataURL(this.files[0]);
        } else {
            // hiển thị tên file
            $this.closest(".uploader").find(".uploader__preview").text(this.files[0].name);
        }
        $this.closest(".uploader").addClass("is-active");
    }
}