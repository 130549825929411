// style.js
$(document).ready(function() {
    $(".button-click-mobile").click(function(event){
        $(".hd-menu").toggleClass('is-active');
        event.stopPropagation();
    });
    $(window).click(function(e){
        $(".hd-menu").removeClass('is-active');
    });
});

$(document).ready(function() {
    $(window).on("load resize", function() {
        if (window.innerWidth > 1024) {
            var el_parent = $('.header .has_sub-menu'),
                maxWidth = 400, Width = 0;
            for (var i = 0; i < el_parent.length; i++) {
                Width = $(el_parent[i]).find('ul').outerWidth() + 15;
                Width = (Width > maxWidth) ? maxWidth : Width;
                $(el_parent[i]).css('position', 'relative');
                $(el_parent[i]).find('ul').css('width', Width);
            }
        }
    });
});
$(document).ready(function() {
    $(window).scroll(function(){
        var wScroll = $(window).scrollTop();
        if( wScroll > 400 ){
            $("a[ht-backTop]").addClass("is-show");
        }else {
            $("a[ht-backTop]").removeClass("is-show");
        }
    });
    $("a[ht-backTop]").click(function(){
        $("html,body").animate({
            "scrollTop": 0,
        },500);
    });
});